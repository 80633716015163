

.navigationlist {
  width: 100%;
  .emu-navigation {
    background-color: transparent;
    margin-left: 20px;
    margin-right: 20px;
    @include mq('large') {
      margin-left: 128px;
      margin-right: 0;
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      @include mq('large') {
        flex-direction: row;
      }
      .nav-item {
        border: none;
        &:hover {
          background-color: transparent;
        }
        a {
          color: var(--emu-common-colors-white);
        }
      }
      li {
        border-bottom: 1px solid var(--emu-common-colors-white);
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
        padding-top: 0;
        &:first-of-type {
          border-top: 1px solid var(--emu-common-colors-white);
        }
        @include mq('large') {
          border-bottom: none;
          display: inline;
          &:first-of-type {
            border-top: none;
          }
        }
        a {
          border: none;
          padding-bottom: 18px;
          padding-top: 18px;
          width: 100%;
          &:hover {
            background-color: transparent;
            opacity: 0.7;
          }
          @include mq('large') {
            padding-bottom: 0;
            padding-top: 0;
            width: auto;
          }
        }
        span {
          display: flex;
          justify-content: space-between;
          width: 100%;
          &::after {
            background-image: url('../../assets/images/white-left-arrow.svg');
            background-repeat: no-repeat;
            content: ' ';
            display: inline-block;
            height: 16px;
            margin-bottom: auto;
            margin-top: auto;
            width: 10px;
            @include mq('large') {
              display: none;
            }
          }
        }
      }
    }
  }
}
.navbar_container {
  background-color: var(--emu-common-colors-true-black);
  border-bottom: 1px var(--emu-common-colors-yellow-100) solid;
  width: 100%;
  .navbar_inner-container {
    align-items: center;
    background-color: var(--emu-common-colors-true-black);
    display: flex;
    justify-content: space-between;
    max-width: 1124px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 18px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 18px;
    > .container {
      width: 100%;
    }
    img {
      height: 53px;
    }
  }
  .emu-navigation__item {
    font-size: 18px;
  }
  #nav-modal {
    background-color: var(--emu-common-colors-true-black);
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 89px;
    z-index: var(--emu-common-other-z-index-overlay);
    @include mq('large') {
      position: static;
    }
    &:not(.js-toggle-on) {
      display: none;
      @include mq('large') {
        display: block;
      }
    }
    .additional-links {
      background-color: transparent;
      text-align: center;
      @include mq('large') {
        display: none;
      }
      .button {
        padding-top: 18px;
      }
      a {
        &:hover {
          opacity: 0.7;
        }
      }
      a, 
      p {
        color: var(--emu-common-colors-white);
        font-size: 14px;
        line-height: 22px;
        text-decoration: none;
      }
      p {
        margin-bottom: 30px;
        margin-top: 30px;
      }
    }
    .emu-navigation__content-wrapper {
      position: static;
    }
    .linkedin-link {
      align-content: center;
      display: inline-flex;
      flex-direction: row-reverse;
      svg {
        margin-bottom: auto;
        margin-left: 8px;
        margin-top: auto;
      }
    }
  }
  .button {
    @include mq('large') {
    display: none;
    }
  }
  .nav {
    &__button {
    background-color: transparent !important;
    border: none;
    height: 22px;
    margin-bottom: 10px;
    overflow: visible;
    width: 22px;
      > span {
        display: block;
        &::before,
        &::after {
          content: '';
        }

        &::before,
        .cmp-button__text,
        &::after {
          border-radius: 1px;
          display: block;
          width: 20px;
          height: 2px;
          background-color: var(--emu-common-colors-white);
          margin: 5px 0;
          transition: 0.4s;
        }
        &::before {
          margin-top: 2px;
        }
      }
      &.js-toggle-on {
        > span {
          &::before {
            transform: translate(-2, 6px) rotate(-45deg);
            width: 25px;
          }
        .cmp-button__text {opacity: 0;}
          &::after {
            transform: translate(-2, -7px) rotate(45deg);
            width: 25px;
          }
        }
      }
    }
  }
  .image {
    display: block;
    width: auto;
  }
  .aaaem-container {
    &.sub-nav {
        display: none;
        @include mq('large') {
          display: block;
        }
        > div {
          display: flex;
          justify-content: space-between;
          margin-left: auto;
          margin-right: auto;
          padding-left: 18px;
          padding-right: 18px;
          max-width: 1124px;
          width: 100%;
          > .button,
          > .container {
            width: auto;
          }
          &::before,
          &::after {
            display: none;
          }
        }
        .button,
        .emu-button,
        p,
        span {
          color: var(--emu-common-colors-grey-500);
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 0;
          margin-top: 0;
          text-decoration: none;
        }
        a {
          &:hover {
            color: var(--emu-common-colors-true-black);
            p, span {
              color: var(--emu-common-colors-true-black);
            }
            svg {
              path {
                fill: var(--emu-common-colors-true-black);
              }
            }
          }
        }

        p {
          padding-bottom: 7px;
          padding-top: 7px;
        }
        .button {
          display: inline-block;
          padding-bottom: 7px;
          padding-top: 7px;
        }
        .button-group {
          display: flex;
          margin-left: 0;
          > * {
            margin-right: 20px;
          }
        }
        .linkedin-button {
          align-items: center;
          display: flex;
          flex-direction: row-reverse;
          svg {
            height: 22px;
            margin-left: 8px;
            width: 22px;
          }
        }
      }
  }
}
