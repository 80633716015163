.artia-home {
  &__hero {
    position: relative;
    margin-top: 140px;
    @include mq('large') {
      margin-top: 122px;
    }
    .tissue-matrix {
      position: absolute;
      bottom: 20px;
      right: 0;
      top: 0;
      z-index: 100;
    }
    .cmp-teaser {
        .content-container {
            padding-left: 28px;
            padding-right: 28px;
            padding-top: 85%;
            width: 100%;
            @include mq('medium') {
              padding-bottom: 70px;
              padding-left: 140px;
              padding-top: 130px;
              width: calc(100% - var(--emu-component-cards-teaser-padding-left-narrow) - var(--emu-component-cards-teaser-padding-right-narrow));
            }
            .content {
              margin-bottom: 11%;
              text-align: center;
              @include mq('medium') {
                margin-bottom: auto;
                text-align: left;
              }
            }
            .title-lockup {
              @include mq('medium') {
                max-width: 60%;
              } 
              h2 {
                sup {
                  font-size: 27%;
                  vertical-align: 28px;
                }
              } 
              h2,
              p {
                margin-bottom: 8px;
                margin-top: 8px;
              }
            }
            p {
              sup {
                font-size: 55%;
              }
            }
            .aaaem-teaser {
              &__pretitle {
                font-family: 'OpenSans-Regular', Arial, sans-serif;
                font-size: 16px;
                line-height: 20px;
              }
              &__title {
                font-family: 'OpenSans-SemiBold', Arial, sans-serif;
                font-size: 54px;
                line-height: 60px;
                padding-top: 0;
              }
              &__subtitle {
                font-size: 20px;
                line-height: 28px;
              }
              &__action-container {
                margin-left: auto;
                margin-right: auto;
                margin-top: 55px;
                @include mq('medium') {
                  margin-left: 0;
                }
                a {
                  background-color: var(--emu-semantic-colors-primary-50);
                  border-radius: 15px; 
                  color: var(--emu-common-colors-white);
                  display: flex;
                  flex-direction: row;
                  font-family: 'OpenSans-Regular', Arial, sans-serif;
                  font-size: 16px;
                  line-height: 20px;
                  padding-bottom: 10px;
                  padding-left: 28px;
                  padding-right: 58px;
                  padding-top: 8px;
                  position: relative;
                  text-decoration: none;
                  @include mq('medium') {
                    border-radius: 18px;
                    padding-bottom: 12px;
                    padding-left: 24px;
                    padding-right: 54px;
                    padding-top: 12px;
                  }
                  &::before,
                  &::after {
                    background-color: white;
                    border-radius: 2px; 
                    content: ' ';
                    height: 12px;
                    position: absolute;
                    right: 28px;
                    width: 2px;
                  }
                  &::before {
                    bottom: calc(50% - 3px);
                    transform: translate(0, 50%) rotate(45deg);
                  }
                  &::after {
                    bottom: calc(50% + 4px);
                    transform: translate(0, 50%) rotate(-45deg);
                  }
                  &:focus {
                    background-color: var(--emu-semantic-colors-primary-50);
                    color: var(--emu-common-colors-white);
                    &::before,
                    &::after {
                      background-color: var(--emu-common-colors-white);
                    }
                  }
                  &:hover {
                    background-color: var(--emu-common-colors-white);
                    color: var(--emu-semantic-colors-primary-50);
                    &::before,
                    &::after {
                      background-color: var(--emu-semantic-colors-primary-50);
                    }
                  }
                }
              }
            }
            .cmp-teaser {
              &__description {
                border-left: 2px var(--emu-common-colors-yellow-100) solid;
                border-top: 2px var(--emu-common-colors-yellow-100) solid;
                border-top-left-radius: 15px;
                display: inline-block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 33px;
                padding-left: 26px;
                padding-top: 2px;
                @include mq('medium') {
                  margin-left: 0;
                  margin-top: 26px;
                }
                p {
                  font-family: 'OpenSans-SemiBold', Arial, sans-serif;
                  margin-top: 0;
                  padding-top: 2px;
                }
              }
            }
          }
    }
  }
}

.benefits-overview__container {
  margin-bottom: 12px; 
  margin-top: 40px;
  @include mq('medium') {
    margin-bottom: 45px;
  }
  &.md__max-width {
    > * {
      max-width: 1150px; 
    }
  }
  > .text {
    p {
      font-size: 14px;
      line-height: 18px;
      margin-top: 32px;
      @include mq('medium') {
        margin-top: 36px;
      }
    }
  }
  .legend {
    padding-left: 10px;
    position: relative;
    .special-char {
      left: 0;
      position: absolute; 
    }
  }
}
.benefits-overview {
    display: flex;
    flex-direction: column;
    @include mq('medium') {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @include mq('large') {
      grid-column-gap: 65px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    > .container {
      @include mq('medium') {
      }
    }
    .aaaem-container {
      align-items: center;
      display: grid;
      grid-template-columns: auto 1fr;
    }
    .text {
      p {
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }
    .image {
      margin-bottom: auto;
      margin-right: 15px;
      margin-top: 12px;
    }
}

.our-processing {
  &__container {
    padding-bottom: 64px;
    @include mq('large') {
      padding-bottom: 52px;
    }
    .aaaem-container {
      border-radius: 24px;
      padding-top: 43px;
      margin-top: 40px;
      @include mq('large') {
        padding-top: 61px;
        margin-top: 50px;
      }
      p {
        padding-left: 18px;
        padding-right: 18px;
      }
      img {
        margin: auto;
      }
      .tissue-matrix-mobile {
        @include mq('large') {
          display: none;
        }
        img {
          padding-left: 32px;
          padding-right: 32px;
          width: 100%;
        }
      }
      .tissue-matrix-desktop {
        display: none;
        @include mq('large') {
          display: block;
          img {
            max-width: 784px;
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
      .disclaimer {
        margin: auto;
        max-width: 758px;
        p {
          padding-bottom: 62px;
          @include mq('large') {
            padding-bottom: 76px;
          }
        }
      }
    }
  }
}

.adms-not-the-same {
    > * {
      max-width: 805px;  
    }
    h2 {
      margin: auto; 
    }
  img {
    margin: auto;
  }
}

.demonstrated-response {
  > * {
    max-width: 700px;  
  }   
  .disclaimer {
    p {
      padding-bottom: 48px;
      padding-top: 0;
      @include mq('large') {
        padding-bottom: 55px;
      }
    }
  }
}

.extensive-portfolio {
  display: flex;
  flex-direction: column-reverse;
  max-width: 1194px;  
  @include mq('large') {
    flex-direction: row;
  }
  > .container {
    @include mq('large') {
      max-width: 50%;
    }
  }
  .lock-up {
    h2 {
      padding-top: 0;
      margin-bottom: 0;
      text-align: center;
      @include mq('large') {
        text-align: left;
      }
    }
    h3 {
      font-family: 'OpenSans-SemiBold', Arial, sans-serif;
      font-size: 20px;
      font-weight: normal;
      line-height: 24px;
      margin-bottom: 0; 
      padding-top: 15px;  
      text-align: center;
      @include mq('large') {
        font-size: 24px;
        line-height: 28px;
        text-align: left;
      }
    }
    p {
      b {
        font-family: 'OpenSans-Regular', Arial, sans-serif;
        font-size: 18px;
        font-weight: normal;
        line-height: 24px;
        text-align: center;
        @include mq('large') {
          text-align: left;
        }
      }
    }
    ul {
      padding-left: 0;
      li {
        margin-left: 5px;
        &::marker {
          color: var(--emu-semantic-colors-primary-50);
          content: '•';
        }
        p {
          margin-left: 10px;
        }
      }
    }
    .disclaimer {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 24px;
      text-align: center;
      @include mq('large') {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 50px;
        text-align: left;
      }
    }
  }
  .image {
    margin: auto;
  }
}

.stretch-consistency {
  h2 {
    margin-bottom: 15px;
  }
  h3 {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 750px;
    padding-top: 0;
    @include mq('large') {
      margin-bottom: 30px;
    }
  }
  .disclaimer {
    p {
      margin-bottom: 0;
      margin-top: 8px;
      padding-bottom: 33px;
      @include mq('large') {
        margin-left: 56px;
        margin-right: 56px;
        padding-top: 0;
        padding-bottom: 61px;
      }
      &:nth-of-type(1) {
        margin-bottom: 8px;
        margin-top: 14px;
        padding-bottom: 0;
        @include mq('large') {
          padding-top: 16px;
          padding-bottom: 0;
        }
      }
    }
  }
  img {
    margin: auto;
  }
}

.product-pliability {
  h2 {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 35px;
    padding-top: 20px;
    @include mq('large') {
      margin-bottom: 30px;
      padding-top: 10px;
    }
  }
  .image {
    display: flex;
  }
  .aaaem-image {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.10);
    border-radius: 24px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    @include mq('large') {
      padding-bottom: 50px;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 50px;
    }
  }
  .disclaimer {
    p {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 40px;
      @include mq('large') {
        padding-top: 15px;
        padding-bottom: 110px;
      }
    }
  }
}

.product-shapes {
  position: relative;
  .emu-image {
    display: flex;
    position: absolute;
    z-index: 100; 
    width: 100%;
    @include mq('medium') {
      height: 100%;
      width: 50%;
    }
    img {
      height: auto;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%; 
      padding-bottom: 40px;
      padding-top: 46px;
      width: 375;
      @include mq('medium') {
        padding-left: 54px;
        padding-right: 54px;
        padding-top: 28px;
        width: 100%;
      }
    }
  }
  .cmp-teaser {
    .content-container {
        margin-left: auto;
        padding-bottom: 50px;
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 294px;
        width: 100%;
        @include mq('medium') {
          padding-bottom: 80px;
          padding-left: 0;
          padding-right: 0;
          padding-top: 32px;
          width: 50%;
        }
        .content {
          margin-left: 0;
          margin-right: auto;
          text-align: center;
          @include mq('medium') {
            max-width: 650px;
            padding-right: 70px;
            text-align: left;
          }
          > * {
            margin-left: unset;
          }
          .title-lockup {
            max-width: unset;
            p {
              font-size: 16px;
              line-height: 20px;
              @include mq('medium') {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }
        }
        a {
          background-color: var(--emu-semantic-colors-primary-50);
          border: solid 2px var(--emu-common-colors-white);
          border-radius: 24px;
          color: var(--emu-common-colors-white);
          display: inline-block;
          font-size: 16px;
          font-family: 'OpenSans-Regular', Arial, sans-serif;
          line-height: 26px; 
          margin-top: 14px;
          padding-bottom: 5px;
          padding-left: 23px;
          padding-right: 53px;
          padding-top: 5px;
          position: relative;
          text-decoration: none;
          @include mq('medium') {
            font-size: 18px;
            margin-top: 35px;
            padding-bottom: 11px;
            padding-top: 11px;
          }
          &::before,
          &::after {
            background-color: white;
            border-radius: 2px; 
            content: ' ';
            height: 12px;
            position: absolute;
            right: 28px;
            width: 2px;
          }
          &::before {
            transform: rotate(45deg);
            bottom: 9px;
            @include mq('medium') {
              bottom: 15px;
            }
          }
          &::after {
            top: 8px;
            transform: rotate(-45deg);
            @include mq('medium') {
              top: 14px;
            }
          }
          &:hover {
            background-color: var(--emu-common-colors-white);
            border: solid 2px var(--emu-semantic-colors-primary-50);
            color: var(--emu-semantic-colors-primary-50);
            &::before,
            &::after {
              background-color: var(--emu-semantic-colors-primary-50);
            }
          }
        }
      }
  }
  &::before,
  &::after {
    background-color: var(--emu-semantic-colors-primary-50);
    content: ' ';
    height: 18px;
    left: 50%;
    position: absolute;
    top: -9px;
    transform: translate(-50%, 0);
    width: 335px;
    z-index: 100;
    @include mq('medium') {
      left: 9vw;
      transform: translate(0, 0);
      width: 455px;
    }
  }
  &::after {
    bottom: -9px;
    top: unset;
    @include mq('medium') {
      left: unset;
      right: 9vw;
    }
  }
}

.market-leaders {
  padding-left: 20px;
  padding-right: 20px;
  @include mq('large') {
    padding-left: 18px;
    padding-right: 18px;
  }
  img,
  .text {
    margin-left: auto;
    margin-right: auto;
  }
  .text {
    text-align: left;
    @include mq('large') {
      max-width: 762px;
      text-align: center;
    }
  }
}

.terms {
  h3 {
    font-size: 20px;
    line-height: 26px;
    padding-top: 4px;
    margin-bottom: 10px;
    @include mq('large') {
      padding-top: 22px;
    }
  }
  .text {
    p {
      font-size: 14px;
      line-height: 18px;
      @include mq('large') {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
    ul {
      padding-left: 6px;
      li {
        font-size: 14px;
        line-height: 18px;
      }
      ::marker {
        content: "•  ";
      }
    }
    a {
      color: var(--emu-component-ingredients-text-color-light)
    }
  }
}

.more-info {
  border: var(--emu-semantic-colors-primary-50) 3px solid;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
  margin-top: 28px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  width: fit-content;
  @include mq('large') {
    margin-bottom: 50px;
    margin-top: 50px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
  }
  h3 {
    color: var(--emu-semantic-colors-primary-50);
    font-family: 'OpenSans-SemiBold', Arial, sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
    padding-top: 0;
  }
}

.references {
  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
    @include mq('large') {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 80px;
    }
  }
}

.terms,
.references {
  @extend %section-max-width-layout;
}

.disclaimer {
  p {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 60px;
    @include mq('large') {
      padding-bottom: 40px;
      padding-top: 14px;
    }
  }
}