header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  .button {
    .nav__button {
      &.emu-button {
        position: relative;
        border: none;
        &:active {
          outline: none;
        }
      > span {
        position: absolute;
        top: 0;
      }
      }
    }
  }
  .contact-us-container {
    position: relative;
    width: 100%;
    @include mq('large') {
      position: fixed;
      right: 0;
      transform: translateY(-50%);
      top: 50%;
    }
    > .button {
      @include mq('large') {
        position: fixed;
        right: -115px;
        transform: translateY(-50%) rotate(-90deg);
        top: 50%;
        width: 280px;
      }
    }
    .hide-show-container {
      overflow: hidden;
      padding-left: 20px;
      padding-right: 20px;
      position: fixed;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.26s ease-in;
      width: 100%;
      @include mq('large') {
        border-top-left-radius: 8px; 
        border-bottom-left-radius: 8px;
        box-shadow: 0px 2px 10px var(--emu-common-colors-grey-600);
        right: 0;
        transform: translateY(-50%) scaleY(0)translateX(100px);
        top: 50%;
        width: 476px;
        z-index: var(--emu-common-other-z-index-layer);
        * {
          transform: scaleY(0)
        }
        > .button {
          z-index: 1;
        }
      }
      &.js-toggle-on {
        transform: scaleY(1);
        @include mq('large') {
          
          transform: translateY(-50%) scaleY(1)translateX(0);
          * {
            transform: scaleY(1)
          }
        }
      }
      > .title {
        h3 {
          font-size: 21px;
          line-height: 32px;
          margin-bottom: 0;
          padding-bottom: 15px;
          padding-top: 15px;
        }
        &:first-of-type {
          h3 {
            padding-top: 28px;
          }
        }
      }
      > .text {
        p {
          border-bottom: 1px #DDE0E5 solid;
          margin-bottom: 0;
          margin-top: 0;
          padding-bottom: 18px;
          a {
            color: var(--emu-semantic-colors-primary-50);
            text-decoration: none;
            &:hover {
              color: var(--emu-common-colors-grey-600);
            }
          }
        }
        &:last-of-type {
          p {
            border-bottom: none;
            padding-bottom: 30px;
          }
        }
      }
    }
    &.aaaem-container {
      #open-contact-modal-btn {
        background-color: var(--emu-semantic-colors-primary-50);
        border: none;
        text-align: center;
        width: 100%;
        :hover,
        :active,
        :focus {
          background-color: var(--emu-semantic-colors-primary-50);
        }
        span {
          font-size: 20px;
          line-height: 30px;
          transform: scale(1, 1.2);
          width: 100%;
        }
      }
      #close-contact-modal-btn {
        background-color: transparent;
        border: none;
        height: 20px;
        padding: 0;
        position: absolute;
        right: 0;
        top: 20px;
        width: 20px;
        :hover,
        :active,
        :focus {
          background-color: transparent;
        }
        span {
          display: block;
          position: relative;
          height: 100%;
          width: 100%;
          &::before,
          &::after {
            background-color: var(--emu-common-colors-grey-600);
            border-radius: 10px;
            content: ' ';
            height: 24px;
            right: 8px;
            top: -3px;
            width: 2.5px;
          }
          &::before {
            position: absolute;
            transform: rotate(45deg);
          }
          &::after {
            position: absolute;
            transform: rotate(-45deg);
          }
          > span {
            display: none;
          }
        }
      }
    }
  }
}

@import './nav.scss';