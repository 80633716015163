%section-max-width-layout {
    padding-left: 20px;
    padding-right: 20px;
    @include mq('large') {
      max-width: 1124px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 18px;
      padding-right: 18px;
    }
  }