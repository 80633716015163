[data-component='container-isi-v2'] {
  > div {
    &.aaaem-isi-container {
      background-color: var(--emu-common-colors-grey-600);
      border-top: none;
      .aaaem-isi-container__button-wrapper {
        .emu-button {
          &.aaaem-button {
            padding-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            &:hover,
            &:active,
            &:focus {
              background-color: transparent;
              opacity: 0.5;
              outline: none;
            }
            svg {
              margin-right: 0;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }
  .aaaem-isi-banner {
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 2px;
    padding-top: 0;
    @include mq('large') {
      max-width: 1124px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 18px;
    }
    background-color: var(--emu-common-colors-grey-600);
    p {
      color: var(--emu-common-colors-white);
      margin-bottom: 8px;
      margin-top: 8px;
      b {
        font-family: 'OpenSans-SemiBold', Arial, sans-serif;
        font-weight: 400;
      }
    }
  }
  
  .aaaem-isi-container__isi-content {
    @extend %section-max-width-layout;
    p {
      margin-bottom: 16px;
      margin-top: 0;
      @include mq('large') {
        margin-bottom: 22px;
      }
    }
    a,
    p,
    li {
      color: var(--emu-common-colors-black);
      font-size: 16px;
      line-height: 24px;
      @include mq('large') {
        font-size: 18px;
        line-height: 28px;
      }
      b {
        font-weight: 400;
      }
      b,
      &.text-color-raspberry,
      &.text-color-orange,
      &.text-color-red
       {
        font-family: 'OpenSans-SemiBold', Arial, sans-serif;
      }
      &.text-color-raspberry {
        color: var(--emu-semantic-colors-primary-50);
      }
      &.text-color-orange {
        color: var(--emu-common-colors-orange-50);
      }
      &.text-color-red {
        color: var(--emu-common-colors-red-500);
      }
      &.no-padding-btm {
        margin-bottom: 0;
      }
    }
  }
}
