footer.experiencefragment {
  padding: 0;
}

.footer {
  background-color: var(--emu-semantic-colors-surface-light-secondary);
  .aaaem-container {
    background-color: transparent;
  }
  .upper_section,
  .lower_section {
    @extend %section-max-width-layout;
    margin-left: auto;
    margin-right: auto;
  }
  .top-section-inner-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    @include mq('large') {
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 42px;
      padding-top: 74px;
    }
    > .image {
      padding-bottom: 30px;
      @include mq('large') {
        padding-bottom: 0;
      }
    }
    .link-wrapper {
      @include mq('large') {
        align-items: center;
        display: flex;
        height: 100%;
      }
      button {
        border: none;
        background-color: transparent;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        span {
          color: var(--emu-common-colors-true-black);
        }
        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
          border: none;
          span {
            color: var(--emu-semantic-colors-primary-50);
          }
        }
      }
      button,
      a {
        color: var(--emu-common-colors-true-black);
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 6px;
        text-decoration: none;
        @include mq('large') {
          margin-bottom: 0;
          margin-right: 30px;
        }
        &:hover {
          color: var(--emu-semantic-colors-primary-50);
        }
      }
      .your-privacy-btn {
        align-content: center;
        display: inline-flex;
        flex-direction: row-reverse;
        svg {
          height: 14px;
          margin-bottom: auto;
          margin-left: 8px;
          margin-top: auto;
          width: 30px;
        }
      }
    }
  }
  .footer__copyright {
    margin-top: 26px;
    p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 0;
      margin-top: 0;
      &:first-of-type {
        padding-bottom: 28px;
        @include mq('large') {
          padding-bottom: 32px;
        }
      }
      &:nth-of-type(2) {
        font-size: 14px;
        line-height: 22px;
        padding-bottom: 40px;
        @include mq('large') {
          padding-bottom: 77px;
        }
      }
    }
  }
  .footer {
    position: relative;
  }
  .backToTop {
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    .aaaem-back-to-top-button {
      position: static;
      margin-right: 20px;
    }
    svg {
      height: 46px;
      width: auto;
    }
  }
}