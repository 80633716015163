// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.section-layout {
  &__standard {
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    @include mq('medium') {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.md__max-width {
  > * { 
    margin: auto;
    max-width: 905px;
  }
}

.bg__color-medium {
  background-color: var(--emu-semantic-colors-surface-light-secondary);
  .cmp-experiencefragment & {
    background-color: var(--emu-semantic-colors-surface-light-secondary);
  }
}
