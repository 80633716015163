body {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-regular);
  font-size: 16px;
  line-height: 20px;
  @include mq('large') {
    font-size: 18px;
    line-height: 24px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
  @include mq('large') {
    font: var(--emu-component-ingredients-title-h1-typograph-wide);
  }
}

h2 {
  color: var(--emu-semantic-colors-primary-50);
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  line-height: 36px;
  margin-bottom: 20px;
  margin-top: 0;
  padding-top: 50px;
  @include mq('large') {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: 46px;
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typograph-narrow);
  font-family: 'OpenSans', Arial, sans-serif;
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: 24px;
  margin-bottom: 25px;
  margin-top: 0;
  padding-top: 20px;
  @include mq('large') {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: 28px;
    margin-bottom: 25px;
    padding-top: 17px;
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
  @include mq('large') {
    font: var(--emu-component-ingredients-title-h4-typograph-wide);
  }
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
  @include mq('large') {
    font: var(--emu-component-ingredients-title-h5-typograph-wide);
  }
}

sup {
  font-size: 45%;
  line-height: 0;
  vertical-align: super;
}

h2 {
  sup {
    font-size: 35%;
    vertical-align: 20px;
  }
}