.cmp-teaser {
  overflow: hidden;
  

  .content-container {
    padding-top: 130px;

    .content {
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }
  }
}

// Top teaser on page
.hero-teaser {
  &.has-media {
    height: 980px;
  }
  
}
