.bg-image-mobile,
.bg-image-desktop {
  img {
    height: 100vh;
    object-fit: cover;
    position: absolute;
    width: 100vw;
  }
}

.bg-image-mobile {
  @include mq('large') {
    display: none;
  }
}
.bg-image-desktop {
  display: none;
  @include mq('large') {
    display: block;
  } 
}

#error-page {
  .error-message-container {
    .text:not(.aaaem-text) {
      height: 100vh;
      margin: auto;
      position: relative;
      width: 100vw;
      .aaaem-text {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        * {
          color: white;
          margin-left: auto;
          margin-right: auto;
          max-width: 664px;
          padding-left: 20px;
          padding-right: 20px;
        }
        h1 {
          font-family: 'OpenSans-SemiBold', Arial, sans-serif;
          font-size: 54px;
          line-height: 60px; 
        }
        p {
          font-size: 16px;
          line-height: 24px;
          @include mq('large') {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }
}