.terms, .references {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  .terms, .references {
    max-width: 1124px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 18px;
    padding-right: 18px;
  }
}

@font-face {
  font-family: OpenSans-Light;
  src: url("resources/fonts/OpenSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans-LightItalic;
  src: url("resources/fonts/OpenSans-LightItalic.ttf") format("ttf");
}

@font-face {
  font-family: OpenSans-Regular;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("resources/fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans-Italic;
  src: url("resources/fonts/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans-SemiBold;
  src: url("resources/fonts/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans-SemiBoldItalic;
  src: url("resources/fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
}

body {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-regular);
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  body {
    font-size: 18px;
    line-height: 24px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-bold);
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

@media (min-width: 1024px) {
  h1 {
    font: var(--emu-component-ingredients-title-h1-typograph-wide);
  }
}

h2 {
  color: var(--emu-semantic-colors-primary-50);
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 50px;
  line-height: 36px;
}

@media (min-width: 1024px) {
  h2 {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
    line-height: 46px;
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typograph-narrow);
  font-family: OpenSans, Arial, sans-serif;
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  margin-top: 0;
  margin-bottom: 25px;
  padding-top: 20px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    margin-bottom: 25px;
    padding-top: 17px;
    line-height: 28px;
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

@media (min-width: 1024px) {
  h4 {
    font: var(--emu-component-ingredients-title-h4-typograph-wide);
  }
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

@media (min-width: 1024px) {
  h5 {
    font: var(--emu-component-ingredients-title-h5-typograph-wide);
  }
}

sup {
  vertical-align: super;
  font-size: 45%;
  line-height: 0;
}

h2 sup {
  vertical-align: 20px;
  font-size: 35%;
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-true-black: #000;
  --emu-common-colors-black: #333;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: red;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-raspberry-100: #ac145a;
  --emu-common-colors-orange-50: #f07622;
  --emu-common-colors-yellow-100: #cad45a;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #e9ebe9;
  --emu-common-colors-grey-300: #b4b8b8;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #888;
  --emu-common-colors-grey-600: #77787b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #faf6f5;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #ead7d2;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #2b1718;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fdfcfc;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #fcf9f8;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #adadae;
  --emu-common-colors-dark-grey-400: #8e8f90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #fdfcfc;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: OpenSans-Light, Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 32px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 42px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: 200ms;
  --emu-common-other-time-transition-base: 400ms;
  --emu-common-other-time-transition-long: 600ms;
  --emu-common-other-time-transition-xl: 1000ms;
  --emu-common-other-time-duration-instant: 400ms;
  --emu-common-other-time-duration-short: 2000ms;
  --emu-common-other-time-duration-base: 4000ms;
  --emu-common-other-time-duration-long: 6000ms;
  --emu-common-other-time-delay-none: 0ms;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: 100ms;
  --emu-common-other-time-delay-long: 200ms;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-isi: 500;
  --emu-common-other-z-index-header: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-white);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-surface-light-secondary: var(--emu-common-colors-grey-200);
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-common-colors-medium-brown-500);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-common-colors-medium-brown-600);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-common-colors-medium-brown-600);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-common-colors-medium-brown-600);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-medium-brown-500);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-50: var(--emu-common-colors-raspberry-100);
  --emu-semantic-colors-primary-100: var(--emu-common-colors-medium-brown-100);
  --emu-semantic-colors-primary-200: var(--emu-common-colors-medium-brown-200);
  --emu-semantic-colors-primary-300: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-primary-400: var(--emu-common-colors-medium-brown-400);
  --emu-semantic-colors-primary-500: var(--emu-common-colors-medium-brown-500);
  --emu-semantic-colors-primary-600: var(--emu-common-colors-medium-brown-600);
  --emu-semantic-colors-primary-700: var(--emu-common-colors-medium-brown-700);
  --emu-semantic-colors-primary-800: var(--emu-common-colors-medium-brown-800);
  --emu-semantic-colors-primary-900: var(--emu-common-colors-medium-brown-900);
  --emu-semantic-colors-primary-950: var(--emu-common-colors-medium-brown-950);
  --emu-semantic-colors-primary-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-primary-dark: var(--emu-semantic-colors-primary-200);
  --emu-semantic-colors-secondary-50: var(--emu-common-colors-blush-50);
  --emu-semantic-colors-secondary-100: var(--emu-common-colors-blush-100);
  --emu-semantic-colors-secondary-200: var(--emu-common-colors-blush-200);
  --emu-semantic-colors-secondary-300: var(--emu-common-colors-blush-300);
  --emu-semantic-colors-secondary-400: var(--emu-common-colors-blush-400);
  --emu-semantic-colors-secondary-500: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-secondary-600: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-secondary-700: var(--emu-common-colors-blush-700);
  --emu-semantic-colors-secondary-800: var(--emu-common-colors-blush-800);
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-blush-900);
  --emu-semantic-colors-secondary-950: var(--emu-common-colors-blush-950);
  --emu-semantic-colors-secondary-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-secondary-dark: var(--emu-semantic-colors-secondary-200);
  --emu-semantic-colors-tertiary-50: var(--emu-common-colors-abbvie-blue-50);
  --emu-semantic-colors-tertiary-100: var(--emu-common-colors-abbvie-blue-100);
  --emu-semantic-colors-tertiary-200: var(--emu-common-colors-abbvie-blue-200);
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: var(--emu-semantic-colors-tertiary-500);
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-error-50: var(--emu-common-colors-red-50);
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: var(--emu-common-font-families-sans);
  --emu-semantic-font-families-heading: var(--emu-common-font-families-sans);
  --emu-semantic-font-families-mono: var(--emu-common-font-families-mono);
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-narrow: 8px;
  --emu-semantic-spacing-horizontal-wide: 16px;
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * 2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * 2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-narrow: 8px;
  --emu-semantic-spacing-vertical-wide: 16px;
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .25);
  --emu-semantic-font-sizes-narrow-medium: 20px;
  --emu-semantic-font-sizes-narrow-large: 25px;
  --emu-semantic-font-sizes-narrow-xl: 18px;
  --emu-semantic-font-sizes-narrow-xxl: 32px;
  --emu-semantic-font-sizes-narrow-xxxl: 48.83px;
  --emu-semantic-font-sizes-narrow-small: 16px;
  --emu-semantic-font-sizes-narrow-xs: 12.8px;
  --emu-semantic-font-sizes-wide-medium: 22.5px;
  --emu-semantic-font-sizes-wide-large: 28.13px;
  --emu-semantic-font-sizes-wide-xl: 24px;
  --emu-semantic-font-sizes-wide-xxl: 42px;
  --emu-semantic-font-sizes-wide-xxxl: 54.93px;
  --emu-semantic-font-sizes-wide-small: 18px;
  --emu-semantic-font-sizes-wide-xs: 11.48px;
  --emu-semantic-line-heights-narrow-large: calc(var(--emu-semantic-line-heights-narrow-small) * 1.5);
  --emu-semantic-line-heights-narrow-medium: calc(var(--emu-semantic-line-heights-narrow-small) * 1.5);
  --emu-semantic-line-heights-narrow-xl: calc(var(--emu-semantic-line-heights-narrow-small) * 2);
  --emu-semantic-line-heights-narrow-xxl: calc(var(--emu-semantic-line-heights-narrow-small) * 2);
  --emu-semantic-line-heights-narrow-xxxl: calc(var(--emu-semantic-line-heights-narrow-small) * 2.5);
  --emu-semantic-line-heights-narrow-small: 20px;
  --emu-semantic-line-heights-narrow-xs: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-line-heights-wide-large: calc(var(--emu-semantic-line-heights-wide-small) * 1.5);
  --emu-semantic-line-heights-wide-medium: calc(var(--emu-semantic-line-heights-wide-small) * 1.5);
  --emu-semantic-line-heights-wide-xl: calc(var(--emu-semantic-line-heights-wide-small) * 2);
  --emu-semantic-line-heights-wide-xxl: calc(var(--emu-semantic-line-heights-wide-small) * 2.5);
  --emu-semantic-line-heights-wide-xxxl: calc(var(--emu-semantic-line-heights-wide-small) * 3);
  --emu-semantic-line-heights-wide-small: 20px;
  --emu-semantic-line-heights-wide-xs: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1280px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-component-lists-accordion-item-header-text-color-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: var(--emu-component-lists-accordion-margin-top-narrow) var(--emu-component-lists-accordion-margin-right-narrow) var(--emu-component-lists-accordion-margin-bottom-narrow) var(--emu-component-lists-accordion-margin-left-narrow);
  --emu-component-lists-accordion-margin-wide: var(--emu-component-lists-accordion-margin-top-wide) var(--emu-component-lists-accordion-margin-right-wide) var(--emu-component-lists-accordion-margin-bottom-wide) var(--emu-component-lists-accordion-margin-left-wide);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: var(--emu-component-lists-accordion-item-body-padding-top-narrow) var(--emu-component-lists-accordion-item-body-padding-right-narrow) var(--emu-component-lists-accordion-item-body-padding-bottom-narrow) var(--emu-component-lists-accordion-item-body-padding-left-narrow);
  --emu-component-lists-accordion-item-body-padding-wide: var(--emu-component-lists-accordion-item-body-padding-top-wide) var(--emu-component-lists-accordion-item-body-padding-right-wide) var(--emu-component-lists-accordion-item-body-padding-bottom-wide) var(--emu-component-lists-accordion-item-body-padding-left-wide);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: 400 25px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-lists-accordion-item-header-text-typography-wide: 400 28.13px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: var(--emu-component-lists-accordion-item-header-padding-top-narrow) var(--emu-component-lists-accordion-item-header-padding-right-narrow) var(--emu-component-lists-accordion-item-header-padding-bottom-narrow) var(--emu-component-lists-accordion-item-header-padding-left-narrow);
  --emu-component-lists-accordion-item-header-padding-wide: var(--emu-component-lists-accordion-item-header-padding-top-wide) var(--emu-component-lists-accordion-item-header-padding-right-wide) var(--emu-component-lists-accordion-item-header-padding-bottom-wide) var(--emu-component-lists-accordion-item-header-padding-left-wide);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: 400 48.83px / calc(20px * 2.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-h1-typography-wide: 400 54.93px / calc(20px * 3) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-h2-typography-narrow: 400 32px / calc(20px * 2) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-h2-typography-wide: 400 42px / calc(20px * 2.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-h3-typography-narrow: 400 18px / calc(20px * 2) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-h3-typography-wide: 400 24px / calc(20px * 2) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-h4-typography-narrow: 400 25px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-h4-typography-wide: 400 28.13px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-h5-typography-narrow: 700 20px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-h5-typography-wide: 700 22.5px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow) * .5);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-color-background-default-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-semantic-colors-error-200);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-error-200);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-error-200);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-error-200);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-error-500);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-error-500);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-error-500);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-error-500);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-error-500);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-error-500);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-error-500);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-error-500);
  --emu-component-ingredients-select-input-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-size-height-narrow: var(--emu-semantic-sizing-three-line-height-narrow);
  --emu-component-ingredients-text-input-size-height-wide: var(--emu-semantic-sizing-three-line-height-wide);
  --emu-component-ingredients-text-input-label-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-text-input-label-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-text-input-label-padding-right-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * .5);
  --emu-component-ingredients-text-input-label-padding-right-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * .5);
  --emu-component-ingredients-text-input-label-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-text-input-label-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-text-input-label-padding-left-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * .5);
  --emu-component-ingredients-text-input-label-padding-left-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * .5);
  --emu-component-ingredients-text-input-label-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-padding-top-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * .5);
  --emu-component-ingredients-text-input-text-padding-top-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * .5);
  --emu-component-ingredients-text-input-text-padding-right-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * .5);
  --emu-component-ingredients-text-input-text-padding-right-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * .5);
  --emu-component-ingredients-text-input-text-padding-bottom-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * .5);
  --emu-component-ingredients-text-input-text-padding-bottom-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * .5);
  --emu-component-ingredients-text-input-text-padding-left-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * .5);
  --emu-component-ingredients-text-input-text-padding-left-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * .5);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-xs);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-xs);
  --emu-component-ingredients-text-input-typography-label-narrow: var(--emu-common-font-weight-bold) var(--emu-semantic-font-sizes-narrow-xs) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-component-ingredients-text-input-typography-label-wide: var(--emu-common-font-weight-bold) var(--emu-semantic-font-sizes-wide-xs) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-component-ingredients-radio-input-input-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-input-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-radio-input-input-border-radius-narrow: var(--emu-common-border-radius-small);
  --emu-component-ingredients-radio-input-input-border-radius-wide: var(--emu-common-border-radius-small);
  --emu-component-ingredients-radio-input-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-radio-input-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-input-color-background-focus-light: var(--emu-component-ingredients-radio-input-input-color-background-default-light);
  --emu-component-ingredients-radio-input-input-color-background-focus-dark: var(--emu-component-ingredients-radio-input-input-color-background-default-dark);
  --emu-component-ingredients-radio-input-input-color-background-checked-light: var(--emu-component-ingredients-radio-input-input-color-background-default-light);
  --emu-component-ingredients-radio-input-input-color-background-checked-dark: var(--emu-component-ingredients-radio-input-input-color-background-default-dark);
  --emu-component-ingredients-radio-input-input-color-background-disabled-light: var(--emu-component-ingredients-radio-input-input-color-background-default-light);
  --emu-component-ingredients-radio-input-input-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-input-color-background-default-dark);
  --emu-component-ingredients-radio-input-input-color-background-error-light: var(--emu-component-ingredients-radio-input-input-color-background-default-light);
  --emu-component-ingredients-radio-input-input-color-background-error-dark: var(--emu-component-ingredients-radio-input-input-color-background-default-dark);
  --emu-component-ingredients-radio-input-input-color-background-active-light: var(--emu-component-ingredients-radio-input-input-color-background-default-light);
  --emu-component-ingredients-radio-input-input-color-background-active-dark: var(--emu-component-ingredients-radio-input-input-color-background-default-dark);
  --emu-component-ingredients-radio-input-input-color-background-hover-light: var(--emu-component-ingredients-radio-input-input-color-background-default-light);
  --emu-component-ingredients-radio-input-input-color-background-hover-dark: var(--emu-component-ingredients-radio-input-input-color-background-default-dark);
  --emu-component-ingredients-radio-input-input-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-input-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-input-color-border-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-input-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-input-color-border-checked-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-input-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-input-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-input-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-input-color-border-hover-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-input-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-input-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-input-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-input-gap-narrow: var(--emu-semantic-spacing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-input-gap-wide: var(--emu-semantic-spacing-one-line-height-wide);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-semantic-spacing-horizontal-tworem-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-semantic-spacing-horizontal-tworem-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-radio-input-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-radio-input-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-radio-input-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-radio-input-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: 0px 0px var(--emu-semantic-spacing-one-line-height-narrow) 0px;
  --emu-component-ingredients-radio-input-legend-margin-wide: 0px 0px var(--emu-semantic-spacing-one-line-height-wide) 0px;
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-radio-input-help-message-margin-narrow: 0px;
  --emu-component-ingredients-radio-input-help-message-margin-wide: 0px;
  --emu-component-ingredients-radio-input-help-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-radio-input-help-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: 0px;
  --emu-component-ingredients-radio-input-error-message-margin-wide: 0px;
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-input-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-checkbox-input-input-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-checkbox-input-input-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-input-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-checkbox-input-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-input-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-input-color-background-default-light);
  --emu-component-ingredients-checkbox-input-input-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-input-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-input-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-input-color-background-default-light);
  --emu-component-ingredients-checkbox-input-input-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-input-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-input-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-input-color-background-default-light);
  --emu-component-ingredients-checkbox-input-input-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-input-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-input-color-background-error-light: var(--emu-component-ingredients-checkbox-input-input-color-background-default-light);
  --emu-component-ingredients-checkbox-input-input-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-input-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-input-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-input-color-background-default-light);
  --emu-component-ingredients-checkbox-input-input-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-input-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-input-color-background-active-light: var(--emu-component-ingredients-checkbox-input-input-color-background-default-light);
  --emu-component-ingredients-checkbox-input-input-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-input-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-input-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-input-color-border-default-light);
  --emu-component-ingredients-checkbox-input-input-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-input-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-input-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-input-color-border-default-light);
  --emu-component-ingredients-checkbox-input-input-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-input-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-input-color-border-active-light: var(--emu-component-ingredients-checkbox-input-input-color-border-default-light);
  --emu-component-ingredients-checkbox-input-input-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-input-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-input-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-input-color-border-default-light);
  --emu-component-ingredients-checkbox-input-input-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-input-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-input-gap-narrow: 3px;
  --emu-component-ingredients-checkbox-input-input-gap-wide: 3px;
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-semantic-spacing-half-line-height-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-semantic-spacing-half-line-height-wide);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: 0px 0px var(--emu-semantic-spacing-one-line-height-narrow) 0px;
  --emu-component-ingredients-checkbox-input-legend-margin-wide: 0px 0px var(--emu-semantic-spacing-one-line-height-wide) 0px;
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-checkbox-input-help-message-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-checkbox-input-help-message-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-checkbox-input-help-message-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-checkbox-input-help-message-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-semantic-colors-error-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-semantic-colors-error-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-hover-light);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-focus-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-hover-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-active-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: 400 25px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-containers-scroll-reveal-header-text-typography-wide: 400 28.13px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: 700 20px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: 700 22.5px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: 400 25px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: 400 28.13px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-container-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 800px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: 400 32px / calc(20px * 2) OpenSans - Light, Arial, sans-serif;
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width);
  --emu-component-layers-cookie-consent-banner-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
  --emu-figma-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-figma-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-figma-component-cards-teaser-title-max-width: 66.6667%;
  --emu-figma-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-figma-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-figma-component-cards-teaser-padding-narrow: var(--emu-semantic-spacing-two-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-cards-teaser-padding-wide: var(--emu-semantic-spacing-two-line-height-wide) var(--emu-common-sizing-medium);
  --emu-figma-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-disclaimer-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-cards-teaser-disclaimer-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-figma-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-figma-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-figma-component-feeds-instagram-post-gap-wide: 40px;
  --emu-figma-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-figma-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-figma-component-ingredients-google-map-color-border-light: var(--emu-semantic-colors-none);
  --emu-figma-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-ingredients-google-map-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-ingredients-google-map-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-ingredients-google-map-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-ingredients-google-map-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-figma-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-figma-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-figma-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-border-radius-top-left-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-top-left-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-top-right-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-top-right-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-left-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-left-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-right-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-right-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-actions-back-to-top-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-actions-back-to-top-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-actions-back-to-top-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-actions-back-to-top-border-width-all-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-actions-back-to-top-border-width-all-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-actions-back-to-top-color-border-default-light: var(--emu-common-colors-white);
  --emu-figma-component-actions-back-to-top-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-actions-back-to-top-color-background-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-figma-component-actions-back-to-top-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-figma-component-actions-back-to-top-color-icon-default-light: var(--emu-common-colors-white);
  --emu-figma-component-actions-back-to-top-color-icon-default-dark: var(--emu-common-colors-black);
  --emu-figma-component-containers-scroll-reveal-header-text-typography-narrow: 400 25px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-figma-component-containers-scroll-reveal-header-text-typography-wide: 400 28.13px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-figma-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-typography-narrow: 700 20px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-figma-component-containers-scroll-reveal-cards-header-text-typography-wide: 700 22.5px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-figma-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-cards-text-typography-narrow: 400 25px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-figma-component-containers-scroll-reveal-cards-text-typography-wide: 400 28.13px / calc(20px * 1.5) OpenSans - Light, Arial, sans-serif;
  --emu-figma-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-comparison-slider-divider-color-light: var(--emu-common-colors-white);
  --emu-figma-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-figma-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-figma-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-figma-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-figma-component-containers-comparison-slider-handle-shadow-narrow-light: 0 0 6 0 var(--emu-common-colors-black);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-figma-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-figma-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-figma-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-figma-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-figma-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-figma-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-figma-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-figma-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-figma-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-figma-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-figma-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-figma-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-figma-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-figma-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-figma-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-figma-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-figma-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-figma-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-figma-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-figma-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-figma-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-figma-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-figma-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-figma-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-isi-open-content-size: 30vh;
  --emu-figma-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-figma-component-containers-container-max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  --emu-figma-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-figma-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-figma-component-containers-carousel-gap-horizontal-narrow: calc(var(--emu-semantic-spacing-horizontal-narrow) * 4);
  --emu-figma-component-containers-carousel-gap-horizontal-wide: calc(var(--emu-semantic-spacing-horizontal-wide) * 4);
  --emu-figma-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-one-line-height-narrow);
  --emu-figma-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-two-line-height-wide);
  --emu-figma-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-figma-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-figma-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-figma-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-figma-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-figma-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-figma-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-figma-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-figma-component-containers-carousel-indicators-dots-border-width-all: var(--emu-figma-component-containers-carousel-indicators-dots-border-width-narrow);
  --emu-figma-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-dots-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-dots-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-dots-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-dots-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-figma-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-figma-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-figma-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-figma-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-figma-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-figma-component-containers-carousel-indicators-image-border-width-all: var(--emu-figma-component-containers-carousel-indicators-image-border-width-narrow);
  --emu-figma-component-containers-carousel-indicators-image-border-width-narrow: var(--emu-common-border-width-none);
  --emu-figma-component-containers-carousel-indicators-image-border-width-wide: var(--emu-common-border-width-none);
  --emu-figma-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-figma-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-figma-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-figma-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-figma-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-figma-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-figma-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-figma-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-figma-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-figma-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-figma-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-figma-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-figma-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-figma-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-figma-component-containers-carousel-controls-border-width-all: var(--emu-figma-component-containers-carousel-controls-border-width-narrow);
  --emu-figma-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-none);
  --emu-figma-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-none);
  --emu-figma-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-figma-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-figma-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-figma-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-carousel-controls-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-controls-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-carousel-color-border-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-frame-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-carousel-frame-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-carousel-frame-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-frame-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-frame-border-width-default-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-frame-border-width-default-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-frame-border-width-active-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-frame-border-width-active-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-figma-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-figma-component-layers-modal-body-sizing-width: 80%;
  --emu-figma-component-layers-modal-body-sizing-max-width: 800px;
  --emu-figma-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-figma-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-figma-component-layers-modal-actions-close-text-typography: 400 32px / calc(20px * 2) OpenSans - Light, Arial, sans-serif;
  --emu-figma-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-figma-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-figma-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-figma-component-layers-cookie-consent-banner-max-width: var(--emu-figma-component-containers-container-max-width);
  --emu-figma-component-layers-cookie-consent-banner-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-cookie-consent-banner-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-figma-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-figma-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-figma-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-figma-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-typography-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-width: var(--emu-common-border-width-thin);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-outline-width: var(--emu-semantic-outline-width-thin);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
  scroll-padding-top: 140px;
}

@media (min-width: 1024px) {
  html {
    scroll-padding-top: 126px;
  }
}

html, body {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-body);
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

html .root, body .root {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  max-width: 100%;
  width: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.section-layout__standard {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .section-layout__standard {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.md__max-width > * {
  max-width: 905px;
  margin: auto;
}

.bg__color-medium, .cmp-experiencefragment .bg__color-medium {
  background-color: var(--emu-semantic-colors-surface-light-secondary);
}

.artia-home__hero {
  margin-top: 140px;
  position: relative;
}

@media (min-width: 1024px) {
  .artia-home__hero {
    margin-top: 122px;
  }
}

.artia-home__hero .tissue-matrix {
  z-index: 100;
  position: absolute;
  top: 0;
  bottom: 20px;
  right: 0;
}

.artia-home__hero .cmp-teaser .content-container {
  width: 100%;
  padding-top: 85%;
  padding-left: 28px;
  padding-right: 28px;
}

@media (min-width: 768px) {
  .artia-home__hero .cmp-teaser .content-container {
    width: calc(100% - var(--emu-component-cards-teaser-padding-left-narrow)  - var(--emu-component-cards-teaser-padding-right-narrow));
    padding-top: 130px;
    padding-bottom: 70px;
    padding-left: 140px;
  }
}

.artia-home__hero .cmp-teaser .content-container .content {
  text-align: center;
  margin-bottom: 11%;
}

@media (min-width: 768px) {
  .artia-home__hero .cmp-teaser .content-container .content {
    text-align: left;
    margin-bottom: auto;
  }

  .artia-home__hero .cmp-teaser .content-container .title-lockup {
    max-width: 60%;
  }
}

.artia-home__hero .cmp-teaser .content-container .title-lockup h2 sup {
  vertical-align: 28px;
  font-size: 27%;
}

.artia-home__hero .cmp-teaser .content-container .title-lockup h2, .artia-home__hero .cmp-teaser .content-container .title-lockup p {
  margin-top: 8px;
  margin-bottom: 8px;
}

.artia-home__hero .cmp-teaser .content-container p sup {
  font-size: 55%;
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__pretitle {
  font-family: OpenSans-Regular, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__title {
  padding-top: 0;
  font-family: OpenSans-SemiBold, Arial, sans-serif;
  font-size: 54px;
  line-height: 60px;
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__subtitle {
  font-size: 20px;
  line-height: 28px;
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container {
  margin-top: 55px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container {
    margin-left: 0;
  }
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a {
  background-color: var(--emu-semantic-colors-primary-50);
  color: var(--emu-common-colors-white);
  border-radius: 15px;
  flex-direction: row;
  padding: 8px 58px 10px 28px;
  font-family: OpenSans-Regular, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  .artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a {
    border-radius: 18px;
    padding: 12px 54px 12px 24px;
  }
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:before, .artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:after {
  content: " ";
  height: 12px;
  width: 2px;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  right: 28px;
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:before {
  bottom: calc(50% - 3px);
  transform: translate(0, 50%)rotate(45deg);
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:after {
  bottom: calc(50% + 4px);
  transform: translate(0, 50%)rotate(-45deg);
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:focus {
  background-color: var(--emu-semantic-colors-primary-50);
  color: var(--emu-common-colors-white);
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:focus:before, .artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:focus:after {
  background-color: var(--emu-common-colors-white);
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:hover {
  background-color: var(--emu-common-colors-white);
  color: var(--emu-semantic-colors-primary-50);
}

.artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:hover:before, .artia-home__hero .cmp-teaser .content-container .aaaem-teaser__action-container a:hover:after {
  background-color: var(--emu-semantic-colors-primary-50);
}

.artia-home__hero .cmp-teaser .content-container .cmp-teaser__description {
  border-left: 2px var(--emu-common-colors-yellow-100) solid;
  border-top: 2px var(--emu-common-colors-yellow-100) solid;
  border-top-left-radius: 15px;
  margin-top: 33px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2px;
  padding-left: 26px;
  display: inline-block;
}

@media (min-width: 768px) {
  .artia-home__hero .cmp-teaser .content-container .cmp-teaser__description {
    margin-top: 26px;
    margin-left: 0;
  }
}

.artia-home__hero .cmp-teaser .content-container .cmp-teaser__description p {
  margin-top: 0;
  padding-top: 2px;
  font-family: OpenSans-SemiBold, Arial, sans-serif;
}

.benefits-overview__container {
  margin-top: 40px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .benefits-overview__container {
    margin-bottom: 45px;
  }
}

.benefits-overview__container.md__max-width > * {
  max-width: 1150px;
}

.benefits-overview__container > .text p {
  margin-top: 32px;
  font-size: 14px;
  line-height: 18px;
}

@media (min-width: 768px) {
  .benefits-overview__container > .text p {
    margin-top: 36px;
  }
}

.benefits-overview__container .legend {
  padding-left: 10px;
  position: relative;
}

.benefits-overview__container .legend .special-char {
  position: absolute;
  left: 0;
}

.benefits-overview {
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .benefits-overview {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

@media (min-width: 1024px) {
  .benefits-overview {
    grid-column-gap: 65px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.benefits-overview .aaaem-container {
  grid-template-columns: auto 1fr;
  align-items: center;
  display: grid;
}

.benefits-overview .text p {
  margin-top: 8px;
  margin-bottom: 8px;
}

.benefits-overview .image {
  margin-top: 12px;
  margin-bottom: auto;
  margin-right: 15px;
}

.our-processing__container {
  padding-bottom: 64px;
}

@media (min-width: 1024px) {
  .our-processing__container {
    padding-bottom: 52px;
  }
}

.our-processing__container .aaaem-container {
  border-radius: 24px;
  margin-top: 40px;
  padding-top: 43px;
}

@media (min-width: 1024px) {
  .our-processing__container .aaaem-container {
    margin-top: 50px;
    padding-top: 61px;
  }
}

.our-processing__container .aaaem-container p {
  padding-left: 18px;
  padding-right: 18px;
}

.our-processing__container .aaaem-container img {
  margin: auto;
}

@media (min-width: 1024px) {
  .our-processing__container .aaaem-container .tissue-matrix-mobile {
    display: none;
  }
}

.our-processing__container .aaaem-container .tissue-matrix-mobile img {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
}

.our-processing__container .aaaem-container .tissue-matrix-desktop {
  display: none;
}

@media (min-width: 1024px) {
  .our-processing__container .aaaem-container .tissue-matrix-desktop {
    display: block;
  }

  .our-processing__container .aaaem-container .tissue-matrix-desktop img {
    max-width: 784px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.our-processing__container .aaaem-container .disclaimer {
  max-width: 758px;
  margin: auto;
}

.our-processing__container .aaaem-container .disclaimer p {
  padding-bottom: 62px;
}

@media (min-width: 1024px) {
  .our-processing__container .aaaem-container .disclaimer p {
    padding-bottom: 76px;
  }
}

.adms-not-the-same > * {
  max-width: 805px;
}

.adms-not-the-same h2, .adms-not-the-same img {
  margin: auto;
}

.demonstrated-response > * {
  max-width: 700px;
}

.demonstrated-response .disclaimer p {
  padding-top: 0;
  padding-bottom: 48px;
}

@media (min-width: 1024px) {
  .demonstrated-response .disclaimer p {
    padding-bottom: 55px;
  }
}

.extensive-portfolio {
  max-width: 1194px;
  flex-direction: column-reverse;
  display: flex;
}

@media (min-width: 1024px) {
  .extensive-portfolio {
    flex-direction: row;
  }

  .extensive-portfolio > .container {
    max-width: 50%;
  }
}

.extensive-portfolio .lock-up h2 {
  text-align: center;
  margin-bottom: 0;
  padding-top: 0;
}

@media (min-width: 1024px) {
  .extensive-portfolio .lock-up h2 {
    text-align: left;
  }
}

.extensive-portfolio .lock-up h3 {
  text-align: center;
  margin-bottom: 0;
  padding-top: 15px;
  font-family: OpenSans-SemiBold, Arial, sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .extensive-portfolio .lock-up h3 {
    text-align: left;
    font-size: 24px;
    line-height: 28px;
  }
}

.extensive-portfolio .lock-up p b {
  text-align: center;
  font-family: OpenSans-Regular, Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
}

@media (min-width: 1024px) {
  .extensive-portfolio .lock-up p b {
    text-align: left;
  }
}

.extensive-portfolio .lock-up ul {
  padding-left: 0;
}

.extensive-portfolio .lock-up ul li {
  margin-left: 5px;
}

.extensive-portfolio .lock-up ul li::marker {
  color: var(--emu-semantic-colors-primary-50);
  content: "•";
}

.extensive-portfolio .lock-up ul li p {
  margin-left: 10px;
}

.extensive-portfolio .lock-up .disclaimer {
  text-align: center;
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 16px;
}

@media (min-width: 1024px) {
  .extensive-portfolio .lock-up .disclaimer {
    text-align: left;
    margin-bottom: 50px;
    font-size: 14px;
    line-height: 28px;
  }
}

.extensive-portfolio .image {
  margin: auto;
}

.stretch-consistency h2 {
  margin-bottom: 15px;
}

.stretch-consistency h3 {
  max-width: 750px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
}

@media (min-width: 1024px) {
  .stretch-consistency h3 {
    margin-bottom: 30px;
  }
}

.stretch-consistency .disclaimer p {
  margin-top: 8px;
  margin-bottom: 0;
  padding-bottom: 33px;
}

@media (min-width: 1024px) {
  .stretch-consistency .disclaimer p {
    margin-left: 56px;
    margin-right: 56px;
    padding-top: 0;
    padding-bottom: 61px;
  }
}

.stretch-consistency .disclaimer p:nth-of-type(1) {
  margin-top: 14px;
  margin-bottom: 8px;
  padding-bottom: 0;
}

@media (min-width: 1024px) {
  .stretch-consistency .disclaimer p:nth-of-type(1) {
    padding-top: 16px;
    padding-bottom: 0;
  }
}

.stretch-consistency img {
  margin: auto;
}

.product-pliability h2 {
  margin-bottom: 0;
}

.product-pliability h3 {
  margin-bottom: 35px;
  padding-top: 20px;
}

@media (min-width: 1024px) {
  .product-pliability h3 {
    margin-bottom: 30px;
    padding-top: 10px;
  }
}

.product-pliability .image {
  display: flex;
}

.product-pliability .aaaem-image {
  border-radius: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: inline-block;
  box-shadow: 0 4px 20px #0000001a;
}

@media (min-width: 1024px) {
  .product-pliability .aaaem-image {
    padding: 50px;
  }
}

.product-pliability .disclaimer p {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 40px;
}

@media (min-width: 1024px) {
  .product-pliability .disclaimer p {
    padding-top: 15px;
    padding-bottom: 110px;
  }
}

.product-shapes {
  position: relative;
}

.product-shapes .emu-image {
  z-index: 100;
  width: 100%;
  display: flex;
  position: absolute;
}

@media (min-width: 768px) {
  .product-shapes .emu-image {
    height: 100%;
    width: 50%;
  }
}

.product-shapes .emu-image img {
  height: auto;
  max-width: 100%;
  width: 375px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 46px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .product-shapes .emu-image img {
    width: 100%;
    padding-top: 28px;
    padding-left: 54px;
    padding-right: 54px;
  }
}

.product-shapes .cmp-teaser .content-container {
  width: 100%;
  margin-left: auto;
  padding: 294px 18px 50px;
}

@media (min-width: 768px) {
  .product-shapes .cmp-teaser .content-container {
    width: 50%;
    padding: 32px 0 80px;
  }
}

.product-shapes .cmp-teaser .content-container .content {
  text-align: center;
  margin-left: 0;
  margin-right: auto;
}

@media (min-width: 768px) {
  .product-shapes .cmp-teaser .content-container .content {
    max-width: 650px;
    text-align: left;
    padding-right: 70px;
  }
}

.product-shapes .cmp-teaser .content-container .content > * {
  margin-left: unset;
}

.product-shapes .cmp-teaser .content-container .content .title-lockup {
  max-width: unset;
}

.product-shapes .cmp-teaser .content-container .content .title-lockup p {
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .product-shapes .cmp-teaser .content-container .content .title-lockup p {
    font-size: 18px;
    line-height: 24px;
  }
}

.product-shapes .cmp-teaser .content-container a {
  background-color: var(--emu-semantic-colors-primary-50);
  border: solid 2px var(--emu-common-colors-white);
  color: var(--emu-common-colors-white);
  border-radius: 24px;
  margin-top: 14px;
  padding: 5px 53px 5px 23px;
  font-family: OpenSans-Regular, Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

@media (min-width: 768px) {
  .product-shapes .cmp-teaser .content-container a {
    margin-top: 35px;
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 18px;
  }
}

.product-shapes .cmp-teaser .content-container a:before, .product-shapes .cmp-teaser .content-container a:after {
  content: " ";
  height: 12px;
  width: 2px;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  right: 28px;
}

.product-shapes .cmp-teaser .content-container a:before {
  bottom: 9px;
  transform: rotate(45deg);
}

@media (min-width: 768px) {
  .product-shapes .cmp-teaser .content-container a:before {
    bottom: 15px;
  }
}

.product-shapes .cmp-teaser .content-container a:after {
  top: 8px;
  transform: rotate(-45deg);
}

@media (min-width: 768px) {
  .product-shapes .cmp-teaser .content-container a:after {
    top: 14px;
  }
}

.product-shapes .cmp-teaser .content-container a:hover {
  background-color: var(--emu-common-colors-white);
  border: solid 2px var(--emu-semantic-colors-primary-50);
  color: var(--emu-semantic-colors-primary-50);
}

.product-shapes .cmp-teaser .content-container a:hover:before, .product-shapes .cmp-teaser .content-container a:hover:after {
  background-color: var(--emu-semantic-colors-primary-50);
}

.product-shapes:before, .product-shapes:after {
  background-color: var(--emu-semantic-colors-primary-50);
  content: " ";
  height: 18px;
  width: 335px;
  z-index: 100;
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translate(-50%);
}

@media (min-width: 768px) {
  .product-shapes:before, .product-shapes:after {
    width: 455px;
    left: 9vw;
    transform: translate(0);
  }
}

.product-shapes:after {
  bottom: -9px;
  top: unset;
}

@media (min-width: 768px) {
  .product-shapes:after {
    left: unset;
    right: 9vw;
  }
}

.market-leaders {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  .market-leaders {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.market-leaders img, .market-leaders .text {
  margin-left: auto;
  margin-right: auto;
}

.market-leaders .text {
  text-align: left;
}

@media (min-width: 1024px) {
  .market-leaders .text {
    max-width: 762px;
    text-align: center;
  }
}

.terms h3 {
  margin-bottom: 10px;
  padding-top: 4px;
  font-size: 20px;
  line-height: 26px;
}

@media (min-width: 1024px) {
  .terms h3 {
    padding-top: 22px;
  }
}

.terms .text p {
  font-size: 14px;
  line-height: 18px;
}

@media (min-width: 1024px) {
  .terms .text p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.terms .text ul {
  padding-left: 6px;
}

.terms .text ul li {
  font-size: 14px;
  line-height: 18px;
}

.terms .text ul ::marker {
  content: "•  ";
}

.terms .text a {
  color: var(--emu-component-ingredients-text-color-light);
}

.more-info {
  border: var(--emu-semantic-colors-primary-50) 3px solid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 28px auto;
  padding: 20px;
}

@media (min-width: 1024px) {
  .more-info {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 10px 50px;
  }
}

.more-info h3 {
  color: var(--emu-semantic-colors-primary-50);
  margin-bottom: 0;
  padding-top: 0;
  font-family: OpenSans-SemiBold, Arial, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.references p {
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 1024px) {
  .references p {
    margin-bottom: 80px;
    font-size: 18px;
    line-height: 28px;
  }
}

.disclaimer p {
  padding-bottom: 60px;
  font-size: 14px;
  line-height: 18px;
}

@media (min-width: 1024px) {
  .disclaimer p {
    padding-top: 14px;
    padding-bottom: 40px;
  }
}

.bg-image-mobile img, .bg-image-desktop img {
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100vw;
  position: absolute;
}

@media (min-width: 1024px) {
  .bg-image-mobile {
    display: none;
  }
}

.bg-image-desktop {
  display: none;
}

@media (min-width: 1024px) {
  .bg-image-desktop {
    display: block;
  }
}

#error-page .error-message-container .text:not(.aaaem-text) {
  height: 100vh;
  width: 100vw;
  margin: auto;
  position: relative;
}

#error-page .error-message-container .text:not(.aaaem-text) .aaaem-text {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

#error-page .error-message-container .text:not(.aaaem-text) .aaaem-text * {
  color: #fff;
  max-width: 664px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

#error-page .error-message-container .text:not(.aaaem-text) .aaaem-text h1 {
  font-family: OpenSans-SemiBold, Arial, sans-serif;
  font-size: 54px;
  line-height: 60px;
}

#error-page .error-message-container .text:not(.aaaem-text) .aaaem-text p {
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  #error-page .error-message-container .text:not(.aaaem-text) .aaaem-text p {
    font-size: 18px;
    line-height: 24px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
    max-width: 1124px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 18px;
    padding-right: 18px;
  }
}

[data-component="container-isi-v2"] > div.aaaem-isi-container {
  background-color: var(--emu-common-colors-grey-600);
  border-top: none;
}

[data-component="container-isi-v2"] > div.aaaem-isi-container .aaaem-isi-container__button-wrapper .emu-button.aaaem-button {
  padding: 10px;
}

[data-component="container-isi-v2"] > div.aaaem-isi-container .aaaem-isi-container__button-wrapper .emu-button.aaaem-button:hover, [data-component="container-isi-v2"] > div.aaaem-isi-container .aaaem-isi-container__button-wrapper .emu-button.aaaem-button:active, [data-component="container-isi-v2"] > div.aaaem-isi-container .aaaem-isi-container__button-wrapper .emu-button.aaaem-button:focus {
  opacity: .5;
  background-color: #0000;
  outline: none;
}

[data-component="container-isi-v2"] > div.aaaem-isi-container .aaaem-isi-container__button-wrapper .emu-button.aaaem-button svg {
  margin-right: 0;
}

[data-component="container-isi-v2"] > div.aaaem-isi-container .aaaem-isi-container__button-wrapper .emu-button.aaaem-button span {
  display: none;
}

[data-component="container-isi-v2"] .aaaem-isi-banner {
  background-color: var(--emu-common-colors-grey-600);
  padding: 0 2px 0 20px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-banner {
    max-width: 1124px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 18px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-banner p {
  color: var(--emu-common-colors-white);
  margin-top: 8px;
  margin-bottom: 8px;
}

[data-component="container-isi-v2"] .aaaem-isi-banner p b {
  font-family: OpenSans-SemiBold, Arial, sans-serif;
  font-weight: 400;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content p {
  margin-top: 0;
  margin-bottom: 16px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p {
    margin-bottom: 22px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content a, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li {
  color: var(--emu-common-colors-black);
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content a, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li {
    font-size: 18px;
    line-height: 28px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content a b, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p b, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li b {
  font-weight: 400;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content a b, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content a.text-color-raspberry, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content a.text-color-orange, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content a.text-color-red, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p b, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p.text-color-raspberry, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p.text-color-orange, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p.text-color-red, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li b, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li.text-color-raspberry, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li.text-color-orange, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li.text-color-red {
  font-family: OpenSans-SemiBold, Arial, sans-serif;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content a.text-color-raspberry, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p.text-color-raspberry, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li.text-color-raspberry {
  color: var(--emu-semantic-colors-primary-50);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content a.text-color-orange, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p.text-color-orange, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li.text-color-orange {
  color: var(--emu-common-colors-orange-50);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content a.text-color-red, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p.text-color-red, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li.text-color-red {
  color: var(--emu-common-colors-red-500);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content a.no-padding-btm, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content p.no-padding-btm, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content li.no-padding-btm {
  margin-bottom: 0;
}

.footer .upper_section, .footer .lower_section {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1024px) {
  .footer .upper_section, .footer .lower_section {
    max-width: 1124px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 18px;
    padding-right: 18px;
  }
}

footer.experiencefragment {
  padding: 0;
}

.footer {
  background-color: var(--emu-semantic-colors-surface-light-secondary);
}

.footer .aaaem-container {
  background-color: #0000;
}

.footer .upper_section, .footer .lower_section {
  margin-left: auto;
  margin-right: auto;
}

.footer .top-section-inner-wrapper {
  flex-direction: column;
  padding-top: 40px;
  display: flex;
}

@media (min-width: 1024px) {
  .footer .top-section-inner-wrapper {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 74px;
    padding-bottom: 42px;
  }
}

.footer .top-section-inner-wrapper > .image {
  padding-bottom: 30px;
}

@media (min-width: 1024px) {
  .footer .top-section-inner-wrapper > .image {
    padding-bottom: 0;
  }

  .footer .top-section-inner-wrapper .link-wrapper {
    height: 100%;
    align-items: center;
    display: flex;
  }
}

.footer .top-section-inner-wrapper .link-wrapper button {
  background-color: #0000;
  border: none;
  padding: 0;
}

.footer .top-section-inner-wrapper .link-wrapper button span {
  color: var(--emu-common-colors-true-black);
}

.footer .top-section-inner-wrapper .link-wrapper button:hover, .footer .top-section-inner-wrapper .link-wrapper button:active, .footer .top-section-inner-wrapper .link-wrapper button:focus {
  background-color: #0000;
  border: none;
}

.footer .top-section-inner-wrapper .link-wrapper button:hover span, .footer .top-section-inner-wrapper .link-wrapper button:active span, .footer .top-section-inner-wrapper .link-wrapper button:focus span {
  color: var(--emu-semantic-colors-primary-50);
}

.footer .top-section-inner-wrapper .link-wrapper button, .footer .top-section-inner-wrapper .link-wrapper a {
  color: var(--emu-common-colors-true-black);
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .footer .top-section-inner-wrapper .link-wrapper button, .footer .top-section-inner-wrapper .link-wrapper a {
    margin-bottom: 0;
    margin-right: 30px;
  }
}

.footer .top-section-inner-wrapper .link-wrapper button:hover, .footer .top-section-inner-wrapper .link-wrapper a:hover {
  color: var(--emu-semantic-colors-primary-50);
}

.footer .top-section-inner-wrapper .link-wrapper .your-privacy-btn {
  flex-direction: row-reverse;
  align-content: center;
  display: inline-flex;
}

.footer .top-section-inner-wrapper .link-wrapper .your-privacy-btn svg {
  height: 14px;
  width: 30px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
}

.footer .footer__copyright {
  margin-top: 26px;
}

.footer .footer__copyright p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 26px;
}

.footer .footer__copyright p:first-of-type {
  padding-bottom: 28px;
}

@media (min-width: 1024px) {
  .footer .footer__copyright p:first-of-type {
    padding-bottom: 32px;
  }
}

.footer .footer__copyright p:nth-of-type(2) {
  padding-bottom: 40px;
  font-size: 14px;
  line-height: 22px;
}

@media (min-width: 1024px) {
  .footer .footer__copyright p:nth-of-type(2) {
    padding-bottom: 77px;
  }
}

.footer .footer {
  position: relative;
}

.footer .backToTop {
  justify-content: flex-end;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.footer .backToTop .aaaem-back-to-top-button {
  margin-right: 20px;
  position: static;
}

.footer .backToTop svg {
  height: 46px;
  width: auto;
}

header {
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  position: fixed;
  top: 0;
  left: 0;
}

header .button .nav__button.emu-button {
  border: none;
  position: relative;
}

header .button .nav__button.emu-button:active {
  outline: none;
}

header .button .nav__button.emu-button > span {
  position: absolute;
  top: 0;
}

header .contact-us-container {
  width: 100%;
  position: relative;
}

@media (min-width: 1024px) {
  header .contact-us-container {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  header .contact-us-container > .button {
    width: 280px;
    position: fixed;
    top: 50%;
    right: -115px;
    transform: translateY(-50%)rotate(-90deg);
  }
}

header .contact-us-container .hide-show-container {
  transform-origin: top;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  transition: transform .26s ease-in;
  position: fixed;
  overflow: hidden;
  transform: scaleY(0);
}

@media (min-width: 1024px) {
  header .contact-us-container .hide-show-container {
    box-shadow: 0px 2px 10px var(--emu-common-colors-grey-600);
    width: 476px;
    z-index: var(--emu-common-other-z-index-layer);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    top: 50%;
    right: 0;
    transform: translateY(-50%)scaleY(0)translateX(100px);
  }

  header .contact-us-container .hide-show-container * {
    transform: scaleY(0);
  }

  header .contact-us-container .hide-show-container > .button {
    z-index: 1;
  }
}

header .contact-us-container .hide-show-container.js-toggle-on {
  transform: scaleY(1);
}

@media (min-width: 1024px) {
  header .contact-us-container .hide-show-container.js-toggle-on {
    transform: translateY(-50%)scaleY(1)translateX(0);
  }

  header .contact-us-container .hide-show-container.js-toggle-on * {
    transform: scaleY(1);
  }
}

header .contact-us-container .hide-show-container > .title h3 {
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 21px;
  line-height: 32px;
}

header .contact-us-container .hide-show-container > .title:first-of-type h3 {
  padding-top: 28px;
}

header .contact-us-container .hide-show-container > .text p {
  border-bottom: 1px solid #dde0e5;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 18px;
}

header .contact-us-container .hide-show-container > .text p a {
  color: var(--emu-semantic-colors-primary-50);
  text-decoration: none;
}

header .contact-us-container .hide-show-container > .text p a:hover {
  color: var(--emu-common-colors-grey-600);
}

header .contact-us-container .hide-show-container > .text:last-of-type p {
  border-bottom: none;
  padding-bottom: 30px;
}

header .contact-us-container.aaaem-container #open-contact-modal-btn {
  background-color: var(--emu-semantic-colors-primary-50);
  text-align: center;
  width: 100%;
  border: none;
}

header .contact-us-container.aaaem-container #open-contact-modal-btn :hover, header .contact-us-container.aaaem-container #open-contact-modal-btn :active, header .contact-us-container.aaaem-container #open-contact-modal-btn :focus {
  background-color: var(--emu-semantic-colors-primary-50);
}

header .contact-us-container.aaaem-container #open-contact-modal-btn span {
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  transform: scale(1, 1.2);
}

header .contact-us-container.aaaem-container #close-contact-modal-btn {
  height: 20px;
  width: 20px;
  background-color: #0000;
  border: none;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 0;
}

header .contact-us-container.aaaem-container #close-contact-modal-btn :hover, header .contact-us-container.aaaem-container #close-contact-modal-btn :active, header .contact-us-container.aaaem-container #close-contact-modal-btn :focus {
  background-color: #0000;
}

header .contact-us-container.aaaem-container #close-contact-modal-btn span {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
}

header .contact-us-container.aaaem-container #close-contact-modal-btn span:before, header .contact-us-container.aaaem-container #close-contact-modal-btn span:after {
  background-color: var(--emu-common-colors-grey-600);
  content: " ";
  height: 24px;
  width: 2.5px;
  border-radius: 10px;
  top: -3px;
  right: 8px;
}

header .contact-us-container.aaaem-container #close-contact-modal-btn span:before {
  position: absolute;
  transform: rotate(45deg);
}

header .contact-us-container.aaaem-container #close-contact-modal-btn span:after {
  position: absolute;
  transform: rotate(-45deg);
}

header .contact-us-container.aaaem-container #close-contact-modal-btn span > span {
  display: none;
}

.navigationlist {
  width: 100%;
}

.navigationlist .emu-navigation {
  background-color: #0000;
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation {
    margin-left: 128px;
    margin-right: 0;
  }
}

.navigationlist .emu-navigation ul {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation ul {
    flex-direction: row;
  }
}

.navigationlist .emu-navigation ul .nav-item {
  border: none;
}

.navigationlist .emu-navigation ul .nav-item:hover {
  background-color: #0000;
}

.navigationlist .emu-navigation ul .nav-item a {
  color: var(--emu-common-colors-white);
}

.navigationlist .emu-navigation ul li {
  border-bottom: 1px solid var(--emu-common-colors-white);
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.navigationlist .emu-navigation ul li:first-of-type {
  border-top: 1px solid var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation ul li {
    border-bottom: none;
    display: inline;
  }

  .navigationlist .emu-navigation ul li:first-of-type {
    border-top: none;
  }
}

.navigationlist .emu-navigation ul li a {
  width: 100%;
  border: none;
  padding-top: 18px;
  padding-bottom: 18px;
}

.navigationlist .emu-navigation ul li a:hover {
  opacity: .7;
  background-color: #0000;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation ul li a {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navigationlist .emu-navigation ul li span {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.navigationlist .emu-navigation ul li span:after {
  content: " ";
  height: 16px;
  width: 10px;
  background-image: url("resources/images/white-left-arrow.svg");
  background-repeat: no-repeat;
  margin-top: auto;
  margin-bottom: auto;
  display: inline-block;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation ul li span:after {
    display: none;
  }
}

.navbar_container {
  background-color: var(--emu-common-colors-true-black);
  border-bottom: 1px var(--emu-common-colors-yellow-100) solid;
  width: 100%;
}

.navbar_container .navbar_inner-container {
  background-color: var(--emu-common-colors-true-black);
  max-width: 1124px;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 18px;
  display: flex;
}

.navbar_container .navbar_inner-container > .container {
  width: 100%;
}

.navbar_container .navbar_inner-container img {
  height: 53px;
}

.navbar_container .emu-navigation__item {
  font-size: 18px;
}

.navbar_container #nav-modal {
  background-color: var(--emu-common-colors-true-black);
  height: 100%;
  z-index: var(--emu-common-other-z-index-overlay);
  position: fixed;
  top: 89px;
  left: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .navbar_container #nav-modal {
    position: static;
  }
}

.navbar_container #nav-modal:not(.js-toggle-on) {
  display: none;
}

@media (min-width: 1024px) {
  .navbar_container #nav-modal:not(.js-toggle-on) {
    display: block;
  }
}

.navbar_container #nav-modal .additional-links {
  text-align: center;
  background-color: #0000;
}

@media (min-width: 1024px) {
  .navbar_container #nav-modal .additional-links {
    display: none;
  }
}

.navbar_container #nav-modal .additional-links .button {
  padding-top: 18px;
}

.navbar_container #nav-modal .additional-links a:hover {
  opacity: .7;
}

.navbar_container #nav-modal .additional-links a, .navbar_container #nav-modal .additional-links p {
  color: var(--emu-common-colors-white);
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}

.navbar_container #nav-modal .additional-links p {
  margin-top: 30px;
  margin-bottom: 30px;
}

.navbar_container #nav-modal .emu-navigation__content-wrapper {
  position: static;
}

.navbar_container #nav-modal .linkedin-link {
  flex-direction: row-reverse;
  align-content: center;
  display: inline-flex;
}

.navbar_container #nav-modal .linkedin-link svg {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
}

@media (min-width: 1024px) {
  .navbar_container .button {
    display: none;
  }
}

.navbar_container .nav__button {
  height: 22px;
  width: 22px;
  border: none;
  margin-bottom: 10px;
  overflow: visible;
  background-color: #0000 !important;
}

.navbar_container .nav__button > span {
  display: block;
}

.navbar_container .nav__button > span:before, .navbar_container .nav__button > span:after {
  content: "";
}

.navbar_container .nav__button > span:before, .navbar_container .nav__button > span .cmp-button__text, .navbar_container .nav__button > span:after {
  width: 20px;
  height: 2px;
  background-color: var(--emu-common-colors-white);
  border-radius: 1px;
  margin: 5px 0;
  transition: all .4s;
  display: block;
}

.navbar_container .nav__button > span:before {
  margin-top: 2px;
}

.navbar_container .nav__button.js-toggle-on > span:before {
  width: 25px;
  transform: translate(-2px, 6px)rotate(-45deg);
}

.navbar_container .nav__button.js-toggle-on > span .cmp-button__text {
  opacity: 0;
}

.navbar_container .nav__button.js-toggle-on > span:after {
  width: 25px;
  transform: translate(-2px, -7px)rotate(45deg);
}

.navbar_container .image {
  width: auto;
  display: block;
}

.navbar_container .aaaem-container.sub-nav {
  display: none;
}

@media (min-width: 1024px) {
  .navbar_container .aaaem-container.sub-nav {
    display: block;
  }
}

.navbar_container .aaaem-container.sub-nav > div {
  max-width: 1124px;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
}

.navbar_container .aaaem-container.sub-nav > div > .button, .navbar_container .aaaem-container.sub-nav > div > .container {
  width: auto;
}

.navbar_container .aaaem-container.sub-nav > div:before, .navbar_container .aaaem-container.sub-nav > div:after {
  display: none;
}

.navbar_container .aaaem-container.sub-nav .button, .navbar_container .aaaem-container.sub-nav .emu-button, .navbar_container .aaaem-container.sub-nav p, .navbar_container .aaaem-container.sub-nav span {
  color: var(--emu-common-colors-grey-500);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}

.navbar_container .aaaem-container.sub-nav a:hover, .navbar_container .aaaem-container.sub-nav a:hover p, .navbar_container .aaaem-container.sub-nav a:hover span {
  color: var(--emu-common-colors-true-black);
}

.navbar_container .aaaem-container.sub-nav a:hover svg path {
  fill: var(--emu-common-colors-true-black);
}

.navbar_container .aaaem-container.sub-nav p {
  padding-top: 7px;
  padding-bottom: 7px;
}

.navbar_container .aaaem-container.sub-nav .button {
  padding-top: 7px;
  padding-bottom: 7px;
  display: inline-block;
}

.navbar_container .aaaem-container.sub-nav .button-group {
  margin-left: 0;
  display: flex;
}

.navbar_container .aaaem-container.sub-nav .button-group > * {
  margin-right: 20px;
}

.navbar_container .aaaem-container.sub-nav .linkedin-button {
  flex-direction: row-reverse;
  align-items: center;
  display: flex;
}

.navbar_container .aaaem-container.sub-nav .linkedin-button svg {
  height: 22px;
  width: 22px;
  margin-left: 8px;
}

.navigationlist {
  width: 100%;
}

.navigationlist .emu-navigation {
  background-color: #0000;
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation {
    margin-left: 128px;
    margin-right: 0;
  }
}

.navigationlist .emu-navigation ul {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation ul {
    flex-direction: row;
  }
}

.navigationlist .emu-navigation ul .nav-item {
  border: none;
}

.navigationlist .emu-navigation ul .nav-item:hover {
  background-color: #0000;
}

.navigationlist .emu-navigation ul .nav-item a {
  color: var(--emu-common-colors-white);
}

.navigationlist .emu-navigation ul li {
  border-bottom: 1px solid var(--emu-common-colors-white);
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.navigationlist .emu-navigation ul li:first-of-type {
  border-top: 1px solid var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation ul li {
    border-bottom: none;
    display: inline;
  }

  .navigationlist .emu-navigation ul li:first-of-type {
    border-top: none;
  }
}

.navigationlist .emu-navigation ul li a {
  width: 100%;
  border: none;
  padding-top: 18px;
  padding-bottom: 18px;
}

.navigationlist .emu-navigation ul li a:hover {
  opacity: .7;
  background-color: #0000;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation ul li a {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navigationlist .emu-navigation ul li span {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.navigationlist .emu-navigation ul li span:after {
  content: " ";
  height: 16px;
  width: 10px;
  background-image: url("resources/images/white-left-arrow.svg");
  background-repeat: no-repeat;
  margin-top: auto;
  margin-bottom: auto;
  display: inline-block;
}

@media (min-width: 1024px) {
  .navigationlist .emu-navigation ul li span:after {
    display: none;
  }
}

.navbar_container {
  background-color: var(--emu-common-colors-true-black);
  border-bottom: 1px var(--emu-common-colors-yellow-100) solid;
  width: 100%;
}

.navbar_container .navbar_inner-container {
  background-color: var(--emu-common-colors-true-black);
  max-width: 1124px;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 18px;
  display: flex;
}

.navbar_container .navbar_inner-container > .container {
  width: 100%;
}

.navbar_container .navbar_inner-container img {
  height: 53px;
}

.navbar_container .emu-navigation__item {
  font-size: 18px;
}

.navbar_container #nav-modal {
  background-color: var(--emu-common-colors-true-black);
  height: 100%;
  z-index: var(--emu-common-other-z-index-overlay);
  position: fixed;
  top: 89px;
  left: 0;
  right: 0;
}

@media (min-width: 1024px) {
  .navbar_container #nav-modal {
    position: static;
  }
}

.navbar_container #nav-modal:not(.js-toggle-on) {
  display: none;
}

@media (min-width: 1024px) {
  .navbar_container #nav-modal:not(.js-toggle-on) {
    display: block;
  }
}

.navbar_container #nav-modal .additional-links {
  text-align: center;
  background-color: #0000;
}

@media (min-width: 1024px) {
  .navbar_container #nav-modal .additional-links {
    display: none;
  }
}

.navbar_container #nav-modal .additional-links .button {
  padding-top: 18px;
}

.navbar_container #nav-modal .additional-links a:hover {
  opacity: .7;
}

.navbar_container #nav-modal .additional-links a, .navbar_container #nav-modal .additional-links p {
  color: var(--emu-common-colors-white);
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}

.navbar_container #nav-modal .additional-links p {
  margin-top: 30px;
  margin-bottom: 30px;
}

.navbar_container #nav-modal .emu-navigation__content-wrapper {
  position: static;
}

.navbar_container #nav-modal .linkedin-link {
  flex-direction: row-reverse;
  align-content: center;
  display: inline-flex;
}

.navbar_container #nav-modal .linkedin-link svg {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
}

@media (min-width: 1024px) {
  .navbar_container .button {
    display: none;
  }
}

.navbar_container .nav__button {
  height: 22px;
  width: 22px;
  border: none;
  margin-bottom: 10px;
  overflow: visible;
  background-color: #0000 !important;
}

.navbar_container .nav__button > span {
  display: block;
}

.navbar_container .nav__button > span:before, .navbar_container .nav__button > span:after {
  content: "";
}

.navbar_container .nav__button > span:before, .navbar_container .nav__button > span .cmp-button__text, .navbar_container .nav__button > span:after {
  width: 20px;
  height: 2px;
  background-color: var(--emu-common-colors-white);
  border-radius: 1px;
  margin: 5px 0;
  transition: all .4s;
  display: block;
}

.navbar_container .nav__button > span:before {
  margin-top: 2px;
}

.navbar_container .nav__button.js-toggle-on > span:before {
  width: 25px;
  transform: translate(-2px, 6px)rotate(-45deg);
}

.navbar_container .nav__button.js-toggle-on > span .cmp-button__text {
  opacity: 0;
}

.navbar_container .nav__button.js-toggle-on > span:after {
  width: 25px;
  transform: translate(-2px, -7px)rotate(45deg);
}

.navbar_container .image {
  width: auto;
  display: block;
}

.navbar_container .aaaem-container.sub-nav {
  display: none;
}

@media (min-width: 1024px) {
  .navbar_container .aaaem-container.sub-nav {
    display: block;
  }
}

.navbar_container .aaaem-container.sub-nav > div {
  max-width: 1124px;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
}

.navbar_container .aaaem-container.sub-nav > div > .button, .navbar_container .aaaem-container.sub-nav > div > .container {
  width: auto;
}

.navbar_container .aaaem-container.sub-nav > div:before, .navbar_container .aaaem-container.sub-nav > div:after {
  display: none;
}

.navbar_container .aaaem-container.sub-nav .button, .navbar_container .aaaem-container.sub-nav .emu-button, .navbar_container .aaaem-container.sub-nav p, .navbar_container .aaaem-container.sub-nav span {
  color: var(--emu-common-colors-grey-500);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}

.navbar_container .aaaem-container.sub-nav a:hover, .navbar_container .aaaem-container.sub-nav a:hover p, .navbar_container .aaaem-container.sub-nav a:hover span {
  color: var(--emu-common-colors-true-black);
}

.navbar_container .aaaem-container.sub-nav a:hover svg path {
  fill: var(--emu-common-colors-true-black);
}

.navbar_container .aaaem-container.sub-nav p {
  padding-top: 7px;
  padding-bottom: 7px;
}

.navbar_container .aaaem-container.sub-nav .button {
  padding-top: 7px;
  padding-bottom: 7px;
  display: inline-block;
}

.navbar_container .aaaem-container.sub-nav .button-group {
  margin-left: 0;
  display: flex;
}

.navbar_container .aaaem-container.sub-nav .button-group > * {
  margin-right: 20px;
}

.navbar_container .aaaem-container.sub-nav .linkedin-button {
  flex-direction: row-reverse;
  align-items: center;
  display: flex;
}

.navbar_container .aaaem-container.sub-nav .linkedin-button svg {
  height: 22px;
  width: 22px;
  margin-left: 8px;
}

.cmp-teaser {
  overflow: hidden;
}

.cmp-teaser .content-container {
  padding-top: 130px;
}

.cmp-teaser .content-container .content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hero-teaser.has-media {
  height: 980px;
}

/*# sourceMappingURL=main.css.map */
