@font-face {
  font-family: 'OpenSans-Light';
  src: url('./assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-LightItalic';
  src: url('./assets/fonts/OpenSans/OpenSans-LightItalic.ttf') format('ttf');
}

@font-face {
  font-family: 'OpenSans-Regular';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('./assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: url('./assets/fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
}